<template>
  <v-row>
    <v-col cols="12" class="col-md-2">
      <Anuncio />
    </v-col>
    <v-col cols="12" class="col-md-8" style="min-height: 70vh">
      <v-container class="text-center mt-6">
        <h1 class="text-md-h1 text-h3">Algo salió mal...</h1>
        <h4 class="text-md-h4 text-h5">
          <span class="error--text">404</span> - página no encontrada
        </h4>
        <br />
        <h6 class="text-h6">
          No hemos podido encontrar la página que estás buscando
        </h6>
        <v-btn class="mt-4" large color="primary" @click="back"
          >Volver atrás</v-btn
        >
      </v-container>
    </v-col>
    <v-col cols="12" class="col-md-2">
      <Anuncio />
    </v-col>
  </v-row>
</template>

<script>
import Anuncio from "@/components/Anuncio.vue";
export default {
  components: {
    Anuncio,
  },
  methods: {
    back() {
      window.history.length - this.$store.getters.getHistoryCount > 1
        ? this.$router.go(-2)
        : this.$rotuer.push({ name: "Home" });
    },
  },
};
</script>

<style>
</style>